import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {NotificationEventService} from '../core/notification.service';
import {Customer} from '../../models/customer';
import {NotificationEvent} from '../../models/notification-event';
import {CustomerEvent} from '../../models/customer-event';

@Injectable()
export class ManageEventService {

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationEventService
  ) {}

  private _event: CustomerEvent;

  get event(): CustomerEvent {
    return this._event;
  }

  set event(newCustomer: CustomerEvent) {
    this._event = newCustomer;
    this.notificationService.sendNotificationEvent(
      new NotificationEvent("manage_event_changed", this.event)
    );
  }


  add(model){
    return this.apiService.post("/admin/event/add", model).map(response => {
      const resp = JSON.parse(JSON.stringify(response));
      return resp;
    });
  }

  update(model){
    return this.apiService
      .post("/admin/event/"+this.event.id+"/update", model)
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));

        return resp;
      });
  }

  get(eventId){
    return this.apiService
      .get("/admin/event/"+eventId)
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));

        return resp;
      });
  }

  addEventUserBatch(user_batch: any[]){
    return this.apiService
      .post("/admin/event/"+this.event.id+"/user/add", {users:user_batch })
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));

        return resp;
      });
  }
  removeEventUser(userId:number){
    return this.apiService
      .post("/admin/event/"+this.event.id+"/user/remove", {user_id:userId})
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));

        return resp;
      });
  }

  updateEventUser(model:any){
    return this.apiService
      .post("/admin/event/"+this.event.id+"/user/update", model)
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));

        return resp;
      });
  }

  searchLocations(term: String) {
    return this.apiService
      .post("/admin/event/searchLocations", { term: term })
      .map(response => {
        const resp = JSON.parse(JSON.stringify(response));
        return resp;
      });
  }

}
