import { TopicPrompt } from "./topic-prompt";

export class Topic {
  public id: Number;
  public user_id: Number;
  public customer_id: Number;
  public topic: string;
  public prompts: [TopicPrompt];
  public enabled: boolean;
}
