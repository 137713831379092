import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToLocal'
})
export class DateToLocalPipe implements PipeTransform {

  constructor() {}
  
    transform(value: any, args?: any): any {
      if (value.indexOf('Z') === -1 && value.indexOf('+') === -1) {
          value += 'Z';
      }
      return new Date(value);
    }
  
  }
  